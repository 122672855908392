<template>
  <v-form>
    <v-row>
      <v-col cols="6">
        <v-text-field label="UoM Code" v-model="record.UgpCode"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-text-field label="Group Description" v-model="record.UgpName"></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          v-model="record.BaseUom"
          :items="uom"
          item-text="UomName"
          item-value="id"
          label="Base UoM"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    record: {},
    loader: false,
    uom: []
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.loader = true;
      this.$emit("data", this.record);
    },
    getUom() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/uom`)
        .then(res => {
          self.uom = res.ResponseData;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getUom();
  }
};
</script>